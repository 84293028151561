<template>
    <div class="text-left py-3">
      <!-- <BaseHeader :title="'Clients'"></BaseHeader> -->
  
      <BaseLoading
        style="min-height: 400px; height: 90vh"
        v-if="loading"
      ></BaseLoading>
  
      <section v-else>
        <div class=" mt-2 border-0">
          <div class="">
            <div class="row my-1">
              <div  v-for="(item, index) in SystemPaymentsSummary"
            :key="`${index}pts`" class="col-sm-3">
              <div class="stat-panel bg-gradient-primary">
                  <div class="stat-cell">
                      <i class="icon-stack-check bg-icon"></i>
                      <span class="text-xlg">${{item.value}}</span><br>
                      <span class="text-lg">{{item.key}} Summary</span><br>
                  </div>
              </div>
          </div>
              
            </div>
          </div>
          <div class=" card d-flex align-items-center flex-row w-100 justify-content-between  p-3  text-left my-1" style>
            <h2 class="card-title w-50 font-weight-black text-capitalize">
              System Payments
            </h2>
            <v-text-field
            v-model="$store.state.search"
              dense
              outlined
              label="Filter system payments"
              name="url"
              type="text"
              hide-details
            />
          </div>
          <!-- datatable -->
          <v-data-table
            :mobile-breakpoint="0"
            :headers="systemPaymentsHeaders"
            :items="SystemPayments"
            :search="$store.state.search"
          >
            
          </v-data-table>
         
        </div>
      </section>
    </div>
  </template>
  
  <script>
  import { mapState, mapActions } from "vuex";
  export default {
    name: "SystemPayments",
    computed: {
      ...mapState("payment", ["loading", "SystemPayments", "systemPaymentsHeaders", "SystemPaymentsSummary"]),
    },
    data() { 
      return {
        countries: [],
      };
    },
  
    methods: {
      ...mapActions("payment", ["_getSystemPayments"]),
      // navigate(route) {
      //   this.loading = true;
      //   this._navigate(route)
      //     .catch((err) => console.log(err))
      //     .finally(() => (this.loading = false));
      // },
    },
    mounted() {
      this._getSystemPayments()
        .then((res) => {
          console.log("done====>", res);
        })
        .catch((err) => console.log(err));
    },
  };
  </script>